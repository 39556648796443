import { call, put, takeEvery } from "redux-saga/effects"
import api from "../api" // Asegúrate de que la ruta es correcta.

import {
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_SUCCESS,
  CREATE_OBSERVATION_ERROR,
  GET_OBSERVATIONS,
  GET_OBSERVATIONS_SUCCESS,
  GET_OBSERVATIONS_ERROR,
} from "./actionTypes"

const BASE_URL = process.env.REACT_APP_NEST_API

export function* createObservation(action) {
  try {
    const response = yield call(api.post, BASE_URL, `/observations`, {
      username: action.payload.username,
      observationDate: "2024-10-11T12:00:00Z",
      jobId: action.payload.jobId,
      content: action.payload.content,
    })
    yield put({ type: CREATE_OBSERVATION_SUCCESS, payload: response.data })
  } catch (error) {
    yield put({ type: CREATE_OBSERVATION_ERROR, payload: error })
  }
}

export function* getObservations({ payload: id }) {
  try {
    const response = yield call(api.get, BASE_URL, `/observations/${id}`)
    yield put({
      type: GET_OBSERVATIONS_SUCCESS,
      payload: {
        lastObservation: response?.content,
        userName: response?.username,
      },
    })
  } catch (error) {
    yield put({ type: GET_OBSERVATIONS_ERROR, payload: error })
  }
}

export default function* createObservationSaga() {
  yield takeEvery(CREATE_OBSERVATION, createObservation)
}

export function* getObservationsSaga() {
  yield takeEvery(GET_OBSERVATIONS, getObservations)
}
