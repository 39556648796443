import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  API_ERROR,
} from "./actionTypes"

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
})

export const loginUserSuccess = data => ({
  type: LOGIN_SUCCESS,
  payload: {
    token: data.access_token,
    userId: data.userId,
    userLevel: data.userLevel,
    userName: data.userName,
  },
  success: true,
})

export const loginUserError = error => ({
  type: LOGIN_ERROR,
  payload: {
    error: error?.error || "Unknown error",
    message: error?.message || "An error occurred",
  },
})

export const logoutUser = navigate => ({
  type: LOGOUT_USER,
  payload: { navigate },
})

export const logoutUserSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
})

export const logoutUserError = error => ({
  type: LOGOUT_USER_ERROR,
  payload: {
    error: error.error || "Unknown error",
    message: error.message || "An error occurred",
  },
})

export const apiError = error => ({
  type: API_ERROR,
  payload: {
    error: error.error || "Unknown error",
    message: error.message || "An error occurred",
  },
})
