export const FETCH_TRANSCRIPTION = "FETCH_TRANSCRIPTION"
export const FETCH_TRANSCRIPTION_SUCCESS = "FETCH_TRANSCRIPTION_SUCCESS"
export const FETCH_TRANSCRIPTION_ERROR = "FETCH_TRANSCRIPTION_ERROR"

export const UPDATE_TRANSCRIPTION_EVALUATION = "UPDATE_TRANSCRIPTION_EVALUATION"
export const UPDATE_TRANSCRIPTION_EVALUATION_SUCCESS =
  "UPDATE_TRANSCRIPTION_EVALUATION_SUCCESS"
export const UPDATE_TRANSCRIPTION_EVALUATION_ERROR =
  "UPDATE_TRANSCRIPTION_EVALUATION_ERROR"

export const FETCH_SUMMARY_RESULT = "FETCH_SUMMARY_RESULT"
export const FETCH_SUMMARY_RESULT_SUCCESS = "FETCH_SUMMARY_RESULT_SUCCESS"
export const FETCH_SUMMARY_RESULT_ERROR = "FETCH_SUMMARY_RESULT_ERROR"

