import { text } from "@fortawesome/fontawesome-svg-core"
import React, { useEffect, useState } from "react"
// import { t } from "i18next"
import { useSelector, useDispatch } from "react-redux"
import { useParams, useNavigate } from "react-router-dom"
import {
  fetchJobById,
  fetchFilesInfo,
  fetchSummaryResult,
  updateTranscriptionEvaluation,
  createObservation,
  getObservations,
  unlockJobRequest,
  checkAvailableJob,
} from "store/actions" // Asegúrate de importar tu acción
import JobResultsTemplate from "ui/templates/JobResults"

const JobResultsContainer = () => {
  const navigate = useNavigate()
  const id = useParams()?.id
  const dispatch = useDispatch()
  const [itemStart, setItemStart] = useState(null)
  const [searchText, setSearchText] = useState("")
  // const [userName, setUserName] = useState(null)
  const [newEstadoVenta, setNewEstadoVenta] = useState("")

  const jobData = useSelector(state => state?.JobById?.data || {})
  const fileInfo = useSelector(state => state?.FileInfo?.data || {})
  const transcriptionData = useSelector(
    state => state?.Transcription?.data || {}
  )

  const { userName } = useSelector(state => state?.AuthUser)

  const orandaEvaluation = useSelector(
    state => state?.Transcription?.data?.oranda_analysis_completed?.evaluacion
  )

  const estatus = useSelector(
    state => state?.Transcription?.data?.oranda_analysis_completed?.estatus
  )

  const estado_venta = useSelector(
    state => state?.Transcription?.data?.oranda_analysis_completed?.estado_venta
  )

  const { canAccess, message } = useSelector(state => state?.LeaseJobs || {})

  const [checkedEvaluations, setCheckedEvaluations] = useState([])
  const [checkedItems, setCheckedItems] = useState([])
  const [isEnabledSubmit, setIsEnabledSubmit] = useState(true)
  const [observations, setObservations] = useState("")
  const [errorMessageObservations, setErrorMessageObservations] = useState("")

  // const userData = localStorage.getItem("userData")

  const { isLoadingEvaluation, isLoadingSummary } = useSelector(
    state => state?.UiLoaders
  )

  const observation = useSelector(state => state?.Observation?.data)

  const highlightText = (text, searchTerm) => {
    if (!searchTerm.trim()) {
      return text
    }
    const regex = new RegExp(`(${searchTerm})`, "gi")
    return text.replace(
      regex,
      '<span style="background-color: yellow;">$1</span>'
    )
  }

  const handleOnChangeObservations = e => {
    setObservations(e.target.value)
  }
  // Función para actualizar el array de códigos modificados con su estado final
  const updateCheckedItems = (codigo, newState, checkedItems) => {
    // Crea un nuevo array basado en el array existente de códigos tocados
    const updatedCodesWithState = [...checkedItems]

    // Busca si el código ya existe en el array
    const codeIndex = updatedCodesWithState.findIndex(
      item => item.codigo === codigo
    )

    if (codeIndex !== -1) {
      // Si el código ya existe, actualiza su estado final
      updatedCodesWithState[codeIndex].cumplimiento = newState
    } else {
      // Si no existe, añade un nuevo objeto con el código y su estado final
      updatedCodesWithState.push({ codigo, cumplimiento: newState })
    }

    return updatedCodesWithState // Devuelve el nuevo array con los cambios
  }

  const handleSubmit = () => {
    if (!observations) {
      setErrorMessageObservations("Debe ingresar una observación")
    } else {
      dispatch(
        updateTranscriptionEvaluation({ id, checkedItems, newEstadoVenta })
      )
      dispatch(
        createObservation({
          username: userName, // TODO: Obtener de auth
          observationDate: new Date().toISOString(), // Obtener la fecha actual
          jobId: id,
          content: observations,
        })
      )
    }
  }

  const handleSwitchChange = (codigo, checked) => {
    const index = orandaEvaluation.findIndex(
      evaluation => evaluation.codigo === codigo
    )

    if (index !== -1) {
      const newCheckedEvaluations = [...orandaEvaluation]
      const previousState = newCheckedEvaluations[index].cumplimiento // Estado anterior
      const newState = !checked // Estado final (contrario al recibido)

      // Actualiza el estado de cumplimiento del código
      newCheckedEvaluations[index].cumplimiento = newState
      setCheckedEvaluations(newCheckedEvaluations)
      setIsEnabledSubmit(false)

      // Llamar a la función updateCheckedItems para actualizar el estado de los códigos modificados
      const updatedCheckedItems = updateCheckedItems(
        codigo,
        newState,
        checkedItems
      )
      setCheckedItems(updatedCheckedItems) // Actualiza el estado con el nuevo array
    } else {
      console.error("Elemento no encontrado:", codigo)
    }
  }

  const handleOnChangeStatus = e => {
    setNewEstadoVenta(e.target.value)
  }

  const handleClickBackToList = () => {
    dispatch(unlockJobRequest(id))
    navigate("/job-list")
  }

  useEffect(() => {
    if (id) {
      dispatch(fetchJobById(id))
      // dispatch(fetchTranscription(id))
      dispatch(fetchFilesInfo(id))
      dispatch(fetchSummaryResult(id))
      dispatch(getObservations(id))
    }
  }, [dispatch, id])

  // useEffect(() => {
  //   if (userData) {
  //     setUserName(JSON.parse(userData).userName)
  //   }
  // }, [userData])

  useEffect(() => {
    dispatch(checkAvailableJob(id))
  }, [dispatch, id])

  return (
    <JobResultsTemplate
      fileInfo={fileInfo}
      file_path={jobData.file_path}
      phrase_result={transcriptionData.phrase_result}
      itemStart={itemStart}
      handleItemStart={setItemStart}
      isLoadingEvaluation={isLoadingEvaluation}
      isLoadingSummary={isLoadingSummary}
      evaluations={
        checkedEvaluations.length > 0 ? checkedEvaluations : orandaEvaluation
      }
      full_text={transcriptionData.full_text}
      handleSearchText={setSearchText}
      highlightText={highlightText}
      searchText={searchText}
      handleSwitchChange={handleSwitchChange}
      estado_venta={estatus ? estatus : "PENDIENTE"}
      estatus_oranda={estado_venta}
      handleSubmit={handleSubmit}
      isEnabledSubmit={isEnabledSubmit}
      handleOnChangeObservations={handleOnChangeObservations}
      observations={observations}
      errorMessageObservations={errorMessageObservations}
      handleClickBackToList={handleClickBackToList}
      observation={observation}
      handleOnChangeStatus={handleOnChangeStatus}
      newEstadoVenta={newEstadoVenta}
      canAccess={canAccess}
      message={message}
    />
  )
}

export default JobResultsContainer
