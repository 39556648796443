import {
  IS_LOADING_EVALUATION_START,
  IS_LOADING_EVALUATION_END,
  IS_LOADING_SUMMARY_START,
  IS_LOADING_SUMMARY_END,
} from "./actionTypes"

const initialState = {
  isLoadingEvaluation: false,
  isLoadingSummary: false,
}

const uiLoaders = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING_EVALUATION_START:
      return {
        ...state,
        isLoadingEvaluation: true,
      }
    case IS_LOADING_EVALUATION_END:
      return {
        ...state,
        isLoadingEvaluation: false,
      }

    case IS_LOADING_SUMMARY_START:
      return {
        ...state,
        isLoadingSummary: true,
      }
    case IS_LOADING_SUMMARY_END:
      return {
        ...state,
        isLoadingSummary: false,
      }

    default:
      return state
  }
}

export default uiLoaders
