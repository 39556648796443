import {
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_SUCCESS,
  CREATE_OBSERVATION_ERROR,
  GET_OBSERVATIONS,
  GET_OBSERVATIONS_SUCCESS,
  GET_OBSERVATIONS_ERROR,
} from "./actionTypes"

export const createObservation = data => ({
  type: CREATE_OBSERVATION,
  payload: data,
})

export const createObservationSuccess = data => ({
  type: CREATE_OBSERVATION_SUCCESS,
  payload: data,
})

export const createObservationError = error => ({
  type: CREATE_OBSERVATION_ERROR,
  payload: error,
})

export const getObservations = id => ({
  type: GET_OBSERVATIONS,
  payload: id,
})

export const getObservationsSuccess = data => ({
  type: GET_OBSERVATIONS_SUCCESS,
  payload: data,
})

export const getObservationsError = error => ({
  type: GET_OBSERVATIONS_ERROR,
  payload: error,
})
