import { call, put, takeEvery } from "redux-saga/effects"
import api from "../api" // Asegúrate de que la ruta es correcta.
import {
  FETCH_TRANSCRIPTION,
  UPDATE_TRANSCRIPTION_EVALUATION,
  FETCH_SUMMARY_RESULT,
} from "./actionTypes"
import {
  fetchTranscriptionSuccess,
  fetchTranscriptionError,
  updateTranscriptionEvaluationError,
  updateTranscriptionEvaluationSuccess,
  fetchSummaryResultSuccess,
  fetchSummaryResultError,
} from "./actions"
import {
  isLoadingEvaluationStart,
  isLoadingEvaluationEnd,
  isLoadingSummaryStart,
  isLoadingSummaryEnd,
} from "../loaders/actions"

// La URL base de tu API
const BASE_URL = process.env.REACT_APP_NEST_API

function* fetchTranscription({ payload: id }) {
  yield put(isLoadingSummaryStart())
  try {
    const response = yield call(
      api.get,
      BASE_URL,
      `/transcription-result/${id}/` // job_id en este caso
    )
    yield put(fetchTranscriptionSuccess(response))
    yield put(isLoadingSummaryEnd())
  } catch (error) {
    yield put(fetchTranscriptionError(error.toString()))
    yield put(isLoadingSummaryEnd())
  }
}

function* updateTranscriptionEvaluation({ payload }) {
  yield put(isLoadingEvaluationStart())
  try {
    const response = yield call(
      api.put,
      BASE_URL,
      `/transcription-result/update-evaluation/${payload.id}/`,
      {
        estado_venta: payload.newEstadoVenta,
        evaluacion: payload.checkedItems,
      }
    )
    yield put(updateTranscriptionEvaluationSuccess(response))
    yield put(isLoadingEvaluationEnd())
  } catch (error) {
    yield put(updateTranscriptionEvaluationError(error.toString()))
    yield put(isLoadingEvaluationEnd())
  }
}

function* fetchSummaryResult({ payload: id }) {
  yield put(isLoadingSummaryStart())
  try {
    const response = yield call(
      api.get,
      BASE_URL,
      `/transcription-result/evaluations-summary/${id}/`
    )
    yield put(fetchSummaryResultSuccess(response))
    yield put(isLoadingSummaryEnd())
  } catch (error) {
    yield put(fetchSummaryResultError(error.toString()))
    yield put(isLoadingSummaryEnd())
  }
}

export function* fetchSummaryResultSaga() {
  yield takeEvery(FETCH_SUMMARY_RESULT, fetchSummaryResult)
}

export function* fetchTranscriptionSaga() {
  yield takeEvery(FETCH_TRANSCRIPTION, fetchTranscription)
}

export function* updateTranscriptionEvaluationSaga() {
  yield takeEvery(
    UPDATE_TRANSCRIPTION_EVALUATION,
    updateTranscriptionEvaluation
  )
}
