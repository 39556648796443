import PropTypes from "prop-types"
import React from "react"
import { PersistGate } from "redux-persist/integration/react" // Importa PersistGate
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "./assets/scss/theme.scss"
import fakeBackend from "./helpers/AuthType/fakeBackend"
import Snackbar from "./components/SnackBar"
import { store, persistor } from "./store" // Asegúrate de importar ambos elementos
import { Provider } from "react-redux"

fakeBackend()

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)
  const Layout = getLayout(layoutType)

  localStorage.setItem("I18N_LANGUAGE", "sp")

  return (
    <Provider store={store}>
      {/* PersistGate debe envolver la aplicación para cargar el estado persistido */}
      <PersistGate loading={null} persistor={persistor}>
        <Routes>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={<NonAuthLayout>{route.element}</NonAuthLayout>}
              key={idx}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <Authmiddleware>
                  <Layout>{route.element}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact
            />
          ))}
        </Routes>
      </PersistGate>
    </Provider>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
