import {
  CREATE_OBSERVATION,
  CREATE_OBSERVATION_SUCCESS,
  CREATE_OBSERVATION_ERROR,
  GET_OBSERVATIONS,
  GET_OBSERVATIONS_SUCCESS,
  GET_OBSERVATIONS_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  success: true,
  error: null,
  data: {},
}

export default function observationReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_OBSERVATION:
      return {
        ...state,
        loading: true,
        success: false,
      }
    case CREATE_OBSERVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      }
    case CREATE_OBSERVATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      }
    case GET_OBSERVATIONS:
      return {
        ...state,
        loading: true,
        success: false,
      }
    case GET_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      }
    case GET_OBSERVATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: false,
      }
    default:
      return state
  }
}
