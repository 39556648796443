import React from "react"
import Skeleton from "@mui/material/Skeleton"

const SkeletonList = () => {
  return (
    <>
      <tr>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width="100%" height={50} />
        </td>
        <td>
          <Skeleton variant="text" width={20} height={40} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width="100%" height={50} />
        </td>
        <td>
          <Skeleton variant="text" width={20} height={40} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width="100%" height={50} />
        </td>
        <td>
          <Skeleton variant="text" width={20} height={40} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width="100%" height={50} />
        </td>
        <td>
          <Skeleton variant="text" width={20} height={40} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width="100%" height={50} />
        </td>
        <td>
          <Skeleton variant="text" width={20} height={40} />
        </td>
      </tr>
      <tr>
        <td>
          <Skeleton variant="text" width={50} />
        </td>
        <td>
          <Skeleton variant="text" width="100%" height={50} />
        </td>
        <td>
          <Skeleton variant="text" width={20} height={40} />
        </td>
      </tr>
    </>
  )
}

export default SkeletonList
