import * as React from "react"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import "dayjs/locale/es"
import { styled } from "@mui/material/styles"

const StyledCalendar = styled("div")``

function formatDate(dateString) {
  return new Date(dateString).toISOString().split("T")[0]
}

const BasicCalendar = ({ label, calendarValue, setCalendarValue }) => {
  const [value, setValue] = React.useState(
    calendarValue ? dayjs(calendarValue) : null
  )

  React.useEffect(() => {
    if (calendarValue) {
      setValue(dayjs(calendarValue))
    } else {
      setValue(null)
    }
  }, [calendarValue])

  return (
    <StyledCalendar>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zn">
        <DatePicker
          label={label}
          renderInput={props => <div {...props} />}
          value={value}
          views={["month", "day", "year"]}
          onChange={newValue => {
            setValue(newValue)
            setCalendarValue(newValue ? formatDate(newValue.toDate()) : null)
          }}
          slotProps={{
            textField: {
              size: "small",
              variant: "outlined",
            },
          }}
          sx={{ width: "100%" }}
        />
      </LocalizationProvider>
    </StyledCalendar>
  )
}

export default BasicCalendar
