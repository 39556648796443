import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer from "./reducers"
import rootSaga from "./saga"

const persistConfig = {
  key: "root", // Este es el nombre de la clave donde se almacenará el estado persistido.
  storage, // Usa el almacenamiento local (localStorage o sessionStorage)
  whitelist: ["AuthUser"], // Aquí es donde specifies que el reducer "AuthUser" se debe persistir
}

// Crea el reducer persistido
const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

// Usa composeEnhancers para las herramientas de Redux en desarrollo
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  persistedReducer, // Usa el reducer persistido
  composeEnhancers(applyMiddleware(sagaMiddleware)) // Aplica sagaMiddleware
)

// Crea el persistor
const persistor = persistStore(store)

// Ejecuta las sagas
sagaMiddleware.run(rootSaga)

store.subscribe(() => {
  console.log("Store actualizado:", store.getState()) // Esto mostrará el estado completo del store
})

// Exporta explícitamente ambos elementos
export { store, persistor }
