import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_ERROR,
  API_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  token: null,
  userId: null,
  userLevel: null, // Añadir el rol del usuario
  userName: null,
  error: null,
  success: false,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: action.payload.access_token,
        userId: action.payload.userId,
        userLevel: action.payload.userLevel, // Guardar el rol del usuario
        userName: action.payload.userName,
        success: true,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        success: false,
      }
    case LOGOUT_USER:
      return {
        ...state,
        loading: true,
      }
    case LOGOUT_USER_SUCCESS:
      return initialState
    case LOGOUT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case API_ERROR:
      return {
        ...state,
        error: action.payload.error,
      }
    default:
      return state
  }
}

export default authReducer
