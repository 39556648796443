import {
  FETCH_TRANSCRIPTION,
  FETCH_TRANSCRIPTION_SUCCESS,
  FETCH_TRANSCRIPTION_ERROR,
  UPDATE_TRANSCRIPTION_EVALUATION,
  UPDATE_TRANSCRIPTION_EVALUATION_SUCCESS,
  UPDATE_TRANSCRIPTION_EVALUATION_ERROR,
  FETCH_SUMMARY_RESULT,
  FETCH_SUMMARY_RESULT_SUCCESS,
  FETCH_SUMMARY_RESULT_ERROR,
} from "./actionTypes"

export const fetchTranscription = id => {
  return {
    type: FETCH_TRANSCRIPTION,
    payload: id,
  }
}

export const fetchTranscriptionSuccess = data => {
  return {
    type: FETCH_TRANSCRIPTION_SUCCESS,
    payload: data,
  }
}

export const fetchTranscriptionError = error => {
  return {
    type: FETCH_TRANSCRIPTION_ERROR,
    payload: error,
  }
}

export const fetchSummaryResult = id => {
  return {
    type: FETCH_SUMMARY_RESULT,
    payload: id,
  }
}

export const fetchSummaryResultSuccess = data => {
  return {
    type: FETCH_SUMMARY_RESULT_SUCCESS,
    payload: data,
  }
}

export const fetchSummaryResultError = data => {
  return {
    type: FETCH_SUMMARY_RESULT_ERROR,
    payload: data,
  }
}

export const updateTranscriptionEvaluation = id => {
  return {
    type: UPDATE_TRANSCRIPTION_EVALUATION,
    payload: id,
  }
}

export const updateTranscriptionEvaluationSuccess = data => {
  return {
    type: UPDATE_TRANSCRIPTION_EVALUATION_SUCCESS,
    payload: data,
  }
}

export const updateTranscriptionEvaluationError = data => {
  return {
    type: UPDATE_TRANSCRIPTION_EVALUATION_ERROR,
    payload: data,
  }
}
