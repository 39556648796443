import {
  FETCH_JOB_LIST_VIEW,
  FETCH_JOB_LIST_VIEW_SUCCESS,
  FETCH_JOB_LIST_VIEW_ERROR,
  SEARCH_JOB_BY_RUT,
  SEARCH_JOB_BY_RUT_SUCCESS,
  SEARCH_JOB_BY_RUT_ERROR,
  SEARCH_BY_PARAMS,
  SEARCH_BY_PARAMS_SUCCESS,
  SEARCH_BY_PARAMS_ERROR,
  FETCH_CAMPANAS,
  FETCH_CAMPANAS_SUCCESS,
  FETCH_CAMPANAS_ERROR,
} from "./actionTypes"

export const fetchJobListView = (page = 1, limit = 10) => ({
  type: FETCH_JOB_LIST_VIEW,
  payload: { page, limit },
})

export const fetchJobListViewSuccess = data => ({
  type: FETCH_JOB_LIST_VIEW_SUCCESS,
  payload: {
    data: data.data,
    totalPages: data.totalPages,
    total: data.total,
    page: data.page,
    limit: data.limit,
  },
})

export const fetchJobListViewError = error => ({
  type: FETCH_JOB_LIST_VIEW_ERROR,
  payload: error,
})

export const searchJobByRut = (rut, page = 1, limit = 10) => ({
  type: SEARCH_JOB_BY_RUT,
  payload: { rut, page, limit },
})

export const searchJobByRutSuccess = data => ({
  type: SEARCH_JOB_BY_RUT_SUCCESS,
  payload: {
    data: data.data,
    totalPages: data.totalPages,
    total: data.total,
    page: data.page,
    limit: data.limit,
  },
})

export const searchJobByRutError = error => ({
  type: SEARCH_JOB_BY_RUT_ERROR,
  payload: error,
})

export const fetchCampanas = () => ({
  type: FETCH_CAMPANAS,
})

export const fetchCampanasSuccess = data => ({
  type: FETCH_CAMPANAS_SUCCESS,
  payload: data?.data,
})

export const fetchCampanasError = error => ({
  type: FETCH_CAMPANAS_ERROR,
  payload: error,
})

export const searchByParams = (query, page = 1, limit = 10) => {
  console.log("searchByParams action", { query, page, limit })
  return {
    type: SEARCH_BY_PARAMS,
    payload: { query, page, limit },
  }
}

export const searchByParamsSuccess = data => ({
  type: SEARCH_BY_PARAMS_SUCCESS,
  payload: {
    data: data.data,
    totalPages: data.totalPages,
    total: data.total,
    page: data.page,
    limit: data.limit,
  },
})

export const searchByParamsError = error => ({
  type: SEARCH_BY_PARAMS_ERROR,
  payload: error,
})
