import { call, put, takeLatest } from "redux-saga/effects"
import {
  FETCH_JOB_LIST_VIEW,
  SEARCH_JOB_BY_RUT,
  SEARCH_BY_PARAMS,
  FETCH_CAMPANAS,
} from "./actionTypes"
import api from "../api"
import {
  fetchJobListViewSuccess,
  fetchJobListViewError,
  searchJobByRutSuccess,
  searchJobByRutError,
  searchByParamsSuccess,
  searchByParamsError,
  fetchCampanasSuccess,
  fetchCampanasError,
} from "./actions"

const BASE_URL = process.env.REACT_APP_NEST_API
// Este mono trae la informacion de la tabla y pagina de la vista
function* fetchJobList(action) {
  try {
    const { page, limit } = action.payload
    const response = yield call(
      api.get,
      BASE_URL,
      `/view/table-info?page=${page}&limit=${limit}`
    )
    yield put(
      fetchJobListViewSuccess({
        data: response.data,
        totalPages: response.totalPages,
        total: response.total,
        page: response.page,
        limit: response.limit,
      })
    )
  } catch (error) {
    yield put(fetchJobListViewError(error.toString()))
  }
}

function* searchJobByRut(action) {
  try {
    const { rut, page, limit } = action.payload
    const response = yield call(
      api.get,
      BASE_URL,
      `/view/table-info/rut/${rut}?page=${page}&limit=${limit}`
    )
    yield put(
      searchJobByRutSuccess({
        data: response.data,
        totalPages: response.totalPages,
        total: response.total,
        page: response.page,
        limit: response.limit,
      })
    )
  } catch (error) {
    yield put(searchJobByRutError(error.toString()))
  }
}

function* searchByParams(action) {
  console.log("🚀 ~ function*searchByParams ~ action:", action)
  try {
    const { query, page, limit } = action.payload
    const response = yield call(
      api.post,
      BASE_URL,
      `/view/table-info/searchByParams?page=${page}&limit=${limit}`,
      query
    )
    yield put(
      searchByParamsSuccess({
        data: response.data,
        totalPages: response.totalPages,
        total: response.total,
        page: response.page,
        limit: response.limit,
      })
    )
  } catch (error) {
    yield put(searchByParamsError(error.toString()))
  }
}

function* fetchCampanas() {
  try {
    const response = yield call(api.get, BASE_URL, "/view/campanas")
    yield put(fetchCampanasSuccess(response))
  } catch (error) {
    yield put(fetchCampanasError(error.toString()))
  }
}

function* FetchJobListViewSaga() {
  yield takeLatest(FETCH_JOB_LIST_VIEW, fetchJobList)
  yield takeLatest(SEARCH_JOB_BY_RUT, searchJobByRut)
  yield takeLatest(SEARCH_BY_PARAMS, searchByParams)
  yield takeLatest(FETCH_CAMPANAS, fetchCampanas)
}

export { FetchJobListViewSaga }
