import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DashboardTemplate from "ui/templates/Dashboard"

const DashboardContainer = () => {
  const navigate = useNavigate()
  const [name, setName] = useState("Usuario")
  const [userName, setUserName] = useState("")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const interval = setInterval(() => {
      const userData = JSON.parse(localStorage.getItem("userData"))
      if (userData && userData.name) {
        setUserName(userData.userName)
        setName(userData.name)
        setLoading(false)
        clearInterval(interval)
      }
    }, 100) // Verifica cada 100ms hasta que se obtenga el nombre

    return () => clearInterval(interval)
  }, [])

  const now = new Date()
  const hours = now.getHours()
  let greeting = "un buen día"
  if (hours >= 12 && hours < 18) {
    greeting = "una buena tarde"
  } else if (hours >= 18) {
    greeting = "buenas noches"
  }

  const cardsData = [
    {
      title: "Audios Procesados",
      value: "1983",
      trendDirection: "up",
      percentage: "18.89",
    },
    {
      title: "Audios En Proceso",
      value: "0",
      trendDirection: "up",
      percentage: "24.07",
    },
    {
      title: "Total Audios",
      value: "173",
      trendDirection: "up",
      percentage: "8.41",
    },
    {
      title: "Audios con Errores",
      value: "0",
      trendDirection: "down",
      percentage: "20.63",
    },
  ]

  return (
    <DashboardTemplate
      cardsData={cardsData}
      navigate={navigate}
      userName={name}
      grettings={greeting}
      isLoading={loading}
    />
  )
}

export default DashboardContainer
