import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"
import AllJobs from "./jobList/getAllJobs/reducers"
import JobById from "./jobList/getJobById/reducers"
import DeleteJob from "./jobList/deleteJobById/reducers"
import Transcription from "./transcription/reducers"
import Upload from "./upload/reducers"
import ServerEvents from "./serverEvents/reducers"

// LEMUR Analysis
import LemurAnalisys from "./lemurAnalisys/reducers"

// Authentication
import AuthUser from "./auth/login/reducer"
// import Account from "./auth/register/reducer"
// import ForgetPassword from "./auth/forgetpwd/reducer"
// import Profile from "./auth/profile/reducer"

// Files Info
import { filesInfoReducer, searchFilesInfoReducer } from "./filesInfo/reducers"

// Users
import {
  CreateUserReducer,
  GetAllUsersReducer,
  GetUserByIdReducer,
  DeleteUserReducer,
} from "./users/reducers"

// Views

import { jobListViewReducer } from "./views/reducers"

// Ui Loader

import uiLoaders from "./loaders/reducers"

// Observation
import observationReducer from "./observations/reducers"

// Lease Jobs
import LeaseJobReducer from "./leaseJobs/reducers"

const rootReducer = combineReducers({
  // public
  Layout,
  AllJobs,
  JobById,
  DeleteJob,
  Transcription,
  Upload,
  ServerEvents,

  // Users
  CreateUser: CreateUserReducer,
  AllUsers: GetAllUsersReducer,
  DeleteUser: DeleteUserReducer,
  UserById: GetUserByIdReducer,
  AuthUser,

  // LEMUR Analysis
  LemurAnalisys,

  // Files Info

  FileInfo: filesInfoReducer,
  SearchFileInfo: searchFilesInfoReducer,
  // Views
  Views: jobListViewReducer,
  // Ui Loader
  UiLoaders: uiLoaders,
  // Observation
  Observation: observationReducer,

  // Lease Jobs
  LeaseJobs: LeaseJobReducer,
})

export default rootReducer
