import {
  LOCK_JOB_REQUEST,
  LOCK_JOB_SUCCESS,
  LOCK_JOB_ERROR,
  UNLOCK_JOB_REQUEST,
  UNLOCK_JOB_SUCCESS,
  UNLOCK_JOB_ERROR,
  CHECK_AVAILABLE_JOB,
  CHECK_AVAILABLE_JOB_SUCCESS,
  CHECK_AVAILABLE_JOB_ERROR,
} from "./actionTypes"

export const lockJobRequest = jobId => ({
  type: LOCK_JOB_REQUEST,
  payload: { jobId },
})

export const lockJobSuccess = data => ({
  type: LOCK_JOB_SUCCESS,
  payload: data,
})

export const lockJobError = error => ({
  type: LOCK_JOB_ERROR,
  payload: error,
})

export const unlockJobRequest = jobId => ({
  type: UNLOCK_JOB_REQUEST,
  payload: { jobId },
})

export const unlockJobSuccess = data => ({
  type: UNLOCK_JOB_SUCCESS,
  payload: data,
})

export const unlockJobError = error => ({
  type: UNLOCK_JOB_ERROR,
  payload: error,
})

export const checkAvailableJob = jobId => ({
  type: CHECK_AVAILABLE_JOB,
  payload: { jobId },
})

export const checkAvailableJobSuccess = data => ({
  type: CHECK_AVAILABLE_JOB_SUCCESS,
  payload: data,
})

export const checkAvailableJobError = error => ({
  type: CHECK_AVAILABLE_JOB_ERROR,
  payload: error,
})
