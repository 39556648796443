import React, { useEffect, useRef } from "react"
import ReactAudioPlayer from "react-audio-player"

const AudioPlayer = ({ src, start }) => {
  const audioRef = useRef(null)

  useEffect(() => {
    const audioElement = audioRef.current?.audioEl?.current
    if (audioElement && start !== undefined) {
      const startTime = start / 1000 // Convertir milisegundos a segundos
      audioElement.currentTime = startTime
      audioElement.pause()
    }
  }, [start])

  const handlePlay = () => {
    const audioElement = audioRef.current?.audioEl?.current
    if (audioElement) {
      audioElement
        .play()
        .catch(error =>
          console.error("Error al intentar reproducir el audio:", error)
        )
    }
  }

  return (
    <ReactAudioPlayer src={src} ref={audioRef} controls onPlay={handlePlay} />
  )
}

export default AudioPlayer
