export const FETCH_JOB_LIST_VIEW = "FETCH_JOB_LIST_VIEW"
export const FETCH_JOB_LIST_VIEW_SUCCESS = "FETCH_JOB_LIST_VIEW_SUCCESS"
export const FETCH_JOB_LIST_VIEW_ERROR = "FETCH_JOB_LIST_VIEW_ERROR"

export const SEARCH_JOB_BY_RUT = "SEARCH_JOB_BY_RUT"
export const SEARCH_JOB_BY_RUT_SUCCESS = "SEARCH_JOB_BY_RUT_SUCCESS"
export const SEARCH_JOB_BY_RUT_ERROR = "SEARCH_JOB_BY_RUT_ERROR"

export const SEARCH_BY_PARAMS = "SEARCH_BY_PARAMS"
export const SEARCH_BY_PARAMS_SUCCESS = "SEARCH_BY_PARAMS_SUCCESS"
export const SEARCH_BY_PARAMS_ERROR = "SEARCH_BY_PARAMS_ERROR"

export const FETCH_CAMPANAS = "FETCH_CAMPANAS"
export const FETCH_CAMPANAS_SUCCESS = "FETCH_CAMPANAS_SUCCESS"
export const FETCH_CAMPANAS_ERROR = "FETCH_CAMPANAS_ERROR"

export const FETCH_JOB_BY_ID = "FETCH_JOB_BY_ID"
