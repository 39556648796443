import { call, put, takeEvery, select } from "redux-saga/effects"
import {
  LOCK_JOB_REQUEST,
  UNLOCK_JOB_REQUEST,
  CHECK_AVAILABLE_JOB,
} from "./actionTypes"
import {
  lockJobSuccess,
  lockJobError,
  unlockJobSuccess,
  unlockJobError,
  checkAvailableJobSuccess,
  checkAvailableJobError,
} from "./actions"
import api from "../api" // Asegúrate de que la ruta es correcta.

const BASE_URL = process.env.REACT_APP_NEST_API

// Selector para obtener el username desde el estado
const selectUserName = state => state?.AuthUser?.userName

function* lockJob({ payload: { jobId } }) {
  try {
    const userName = yield select(selectUserName) // Obtén el userName desde el estado
    const response = yield call(api.post, BASE_URL, `/job-list/lock/${jobId}`, {
      userId: userName,
    })
    yield put(lockJobSuccess(response))
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error.message
    yield put(
      lockJobError({
        message: errorMessage,
        code: error?.response?.status || 500,
      })
    )
  }
}

function* unlockJob({ payload: { jobId } }) {
  try {
    const userName = yield select(selectUserName) // Obtén el userName desde el estado
    const response = yield call(
      api.post,
      BASE_URL,
      `/job-list/unlock/${jobId}`,
      {
        userId: userName,
      }
    )
    yield put(unlockJobSuccess(response.data))
  } catch (error) {
    yield put(unlockJobError(error.message))
  }
}

function* checkAvailableJob({ payload: { jobId } }) {
  try {
    const userName = yield select(selectUserName) // Obtén el userName desde el estado
    const response = yield call(
      api.post,
      BASE_URL,
      `/job-list/check-access/${jobId}`,
      {
        userId: userName,
      }
    )
    yield put(checkAvailableJobSuccess(response.data))
  } catch (error) {
    yield put(checkAvailableJobError(error.message))
  }
}

export function* leaseJobsSaga() {
  yield takeEvery(LOCK_JOB_REQUEST, lockJob)
  yield takeEvery(UNLOCK_JOB_REQUEST, unlockJob)
  yield takeEvery(CHECK_AVAILABLE_JOB, checkAvailableJob)
}

export default leaseJobsSaga
