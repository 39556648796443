import {
  FETCH_JOB_LIST_VIEW,
  FETCH_JOB_LIST_VIEW_SUCCESS,
  FETCH_JOB_LIST_VIEW_ERROR,
  SEARCH_JOB_BY_RUT,
  SEARCH_JOB_BY_RUT_SUCCESS,
  SEARCH_JOB_BY_RUT_ERROR,
  SEARCH_BY_PARAMS,
  SEARCH_BY_PARAMS_SUCCESS,
  SEARCH_BY_PARAMS_ERROR,
  FETCH_CAMPANAS,
  FETCH_CAMPANAS_SUCCESS,
  FETCH_CAMPANAS_ERROR,
} from "./actionTypes"

const initialState = {
  loading: false,
  data: [],
  error: null,
  totalPages: 1,
  total: 0,
  page: 1,
  limit: 10,
  campanas: [],
  status: [],
}

const jobListViewReducer = (state = initialState, action) => {
  console.log("🚀 ~ jobListViewReducer ~ action:", action)
  switch (action.type) {
    case FETCH_JOB_LIST_VIEW:
    case SEARCH_JOB_BY_RUT:
    case SEARCH_BY_PARAMS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_JOB_LIST_VIEW_SUCCESS:
    case SEARCH_JOB_BY_RUT_SUCCESS:
    case SEARCH_BY_PARAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        total: action.payload.total,
        page: action.payload.page,
        limit: action.payload.limit,
      }
    case FETCH_JOB_LIST_VIEW_ERROR:
    case SEARCH_JOB_BY_RUT_ERROR:
    case SEARCH_BY_PARAMS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case FETCH_CAMPANAS:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case FETCH_CAMPANAS_SUCCESS:
      return {
        ...state,
        loading: false,
        campanas: action.payload,
        status: action.payload.status,
      }
    case FETCH_CAMPANAS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export { jobListViewReducer }
