import React from "react"
import ReactHtmlParser from "react-html-parser"
import { millisToTime } from "ui/assets/functions"
import AudioPlayer from "components/AudioPlayer"
import Modal from "../../../components/Modal"
import {
  Button,
  TextField,
  Switch,
  LinearProgress,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material"
import "./styles.css"
import SkeletonList from "./Skeleton"

const JobResultsTemplate = ({
  file_path,
  fileInfo,
  phrase_result,
  handleItemStart,
  itemStart,
  isLoadingEvaluation,
  isLoadingSummary = false,
  evaluations,
  handleSearchText,
  highlightText,
  searchText,
  handleSubmit,
  handleSwitchChange,
  estado_venta,
  estatus_oranda,
  isEnabledSubmit,
  handleOnChangeObservations,
  observations,
  errorMessageObservations,
  handleClickBackToList,
  observation,
  handleOnChangeStatus,
  newEstadoVenta,
  canAccess,
  message,
}) => {
  return (
    <div className="page-content">
      <div className="row position-relative">
        {isLoadingEvaluation && (
          <div className="position-absolute top-0 start-0 w-100">
            <LinearProgress />
          </div>
        )}
      </div>

      <div className="container-fluid p-3">
        {!canAccess && (
          <Modal
            open={true}
            handleOnCLick={handleClickBackToList}
            title="Registro en uso"
          >
            {message}
          </Modal>
        )}
        <div className="row">
          <div className="col-6">
            <div className="row">
              <div className="col-6">
                <h5 className="page-title">RUT: {fileInfo?.rut_cliente}</h5>
                <p>CLIENTE: {fileInfo?.nombre_apellido_cliente}</p>
                <p>SEGURO: {fileInfo?.seguro}</p>
              </div>
              <div className="col-6">
                <p>CAMPAÑA: {fileInfo?.campana}</p>
                <p>FECHA: {fileInfo?.fecha_venta}</p>
                <FormControl sx={{ width: "40ch" }}>
                  <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={
                      newEstadoVenta
                        ? newEstadoVenta
                        : estatus_oranda || estado_venta
                    }
                    label="Estado"
                    size="small"
                    onChange={e => handleOnChangeStatus(e)}
                  >
                    <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
                    <MenuItem value="RECHAZADA">RECHAZADA</MenuItem>
                    <MenuItem value="APROBADA">APROBADA</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <TextField
                variant="standard"
                label="Buscar en el texto"
                size="small"
                onChange={e => handleSearchText(e.target.value)}
                className="w-50 mb-3"
              />
            </div>
            <div className="row">
              <div className="table-responsive paragraph-table-overflow">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Inicio</th>
                      <th scope="col">Texto</th>
                      <th scope="col">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoadingSummary ? (
                      <>
                        <SkeletonList />
                      </>
                    ) : (
                      phrase_result?.map((item, index) => (
                        <tr key={index}>
                          <td>{millisToTime(item.start)}</td>
                          <td>
                            {ReactHtmlParser(
                              highlightText(item.phrase || "", searchText)
                            )}
                          </td>
                          <td>
                            <Button
                              variant="outlined"
                              onClick={() => handleItemStart(item.start)}
                            >
                              <i className="fas fa-volume-up"></i>
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="row justify-content-center pt-2  mx-0">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">Observaciones</h5>
                    <TextField
                      id="outlined-multiline-static"
                      multiline
                      rows={2}
                      value={observations}
                      onChange={e => handleOnChangeObservations(e)}
                      variant="outlined"
                      fullWidth
                      placeholder={
                        observation
                          ? `${observation.userName}: ${observation.lastObservation}`
                          : "Ingrese Observaciones"
                      }
                      InputProps={{
                        style: {
                          borderColor:
                            errorMessageObservations && !observations
                              ? "red"
                              : undefined, // Cambia el color del borde si hay error y no hay observaciones
                        },
                      }}
                      error={!!errorMessageObservations && !observations} // Activa el estado de error si hay un mensaje y no hay observaciones
                      helperText={
                        !!errorMessageObservations && !observations
                          ? errorMessageObservations
                          : ""
                      } // Muestra el mensaje de error solo si no hay observaciones
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Detalles de la Evaluación</h5>
                  <div className="evaluation-table-overflow">
                    <table className="table evaluation-table-overflow">
                      <thead>
                        <tr>
                          <th>Código</th>
                          <th>Objetivo</th>
                          <th>Evidencia</th>
                          <th>Cumplimiento</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoadingSummary ? (
                          <SkeletonList />
                        ) : (
                          <>
                            {evaluations?.map(evaluation => (
                              <tr key={evaluation.codigo}>
                                <td>{evaluation.codigo}</td>
                                <td>{evaluation.objetivo}</td>
                                <td>{evaluation.evidencia}</td>
                                <td>
                                  <Switch
                                    checked={evaluation.cumplimiento}
                                    onClick={event =>
                                      handleSwitchChange(
                                        evaluation.codigo,
                                        event.target.checked
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mx-0 justify-content-center">
                <div className="col-7">
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    className="w-100"
                    disabled={isEnabledSubmit || isLoadingEvaluation}
                  >
                    Guardar Evaluación
                  </Button>
                </div>
                <div className="col-5">
                  <Button
                    color="success"
                    variant="contained"
                    onClick={handleClickBackToList}
                    className="w-100"
                  >
                    Volver al Listado
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <AudioPlayer src={file_path} start={itemStart} />
        </div>
      </div>
    </div>
  )
}

export default JobResultsTemplate
