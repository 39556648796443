export const LOCK_JOB_REQUEST = "LOCK_JOB_REQUEST"
export const LOCK_JOB_SUCCESS = "LOCK_JOB_SUCCESS"
export const LOCK_JOB_ERROR = "LOCK_JOB_FAILURE"

export const UNLOCK_JOB_REQUEST = "UNLOCK_JOB_REQUEST"
export const UNLOCK_JOB_SUCCESS = "UNLOCK_JOB_SUCCESS"
export const UNLOCK_JOB_ERROR = "UNLOCK_JOB_FAILURE"

export const CHECK_AVAILABLE_JOB = "CHECK_AVAIBLE_JOB"
export const CHECK_AVAILABLE_JOB_SUCCESS = "CHECK_AVAIBLE_JOB_SUCCESS"
export const CHECK_AVAILABLE_JOB_ERROR = "CHECK_AVAIBLE_JOB_ERROR"
