import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import JobListTemplate from "ui/templates/JobList"
import { deleteJobById } from "../../../store/jobList/deleteJobById/actions"
import {
  fetchCampanas,
  fetchJobListView,
  searchJobByRut,
  searchByParams,
  lockJobRequest,
} from "store/actions"
import { useNavigate } from "react-router-dom"

const JobListContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useState({
    rut_cliente: "",
  })
  const [selectedCampana, setSelectedCampana] = useState("")
  const [selectedStatus, setSelectedStatus] = useState("")
  const [calendarValueStart, setCalendarValueStart] = useState("")
  const [calendarValueEnd, setCalendarValueEnd] = useState("")

  const {
    data: jobs,
    isLoading,
    totalPages,
    total,
    page,
    limit,
  } = useSelector(state => state?.Views || {})
  // const { error: deleteError } = useSelector(state => state?.DeleteJob)
  const { campanas, status } = useSelector(
    state => state?.Views?.campanas || {}
  )

  const resetSearchParams = () => {
    setSearchParams({
      rut_cliente: "",
    })
    setSelectedCampana("")
    setSelectedStatus("")
    setCalendarValueStart("")
    setCalendarValueEnd("")
  }

  useEffect(() => {
    dispatch(fetchCampanas())
    dispatch(searchByParams({ campana: "" }))
  }, [dispatch])

  const handleInputSearchChange = e => {
    setSearchParams({
      rut_cliente: e.target.value,
    })
  }

  const handleOnClickViewJob = id => {
    dispatch(lockJobRequest(id))
    navigate(`/job-results/${id}`)
  }

  const handleOnClickDeleteJob = id => {
    dispatch(deleteJobById(id))
  }

  const handleUpdateJobList = () => {
    dispatch(fetchJobListView(1, limit))
  }

  const handleSearch = () => {
    dispatch(searchByParams(searchParams))
  }

  const handlePageChange = newPage => {
    const params = getSearchParams()
    dispatch(searchByParams(params, newPage, limit))
  }

  const handleClickFilter = () => {
    const params = getSearchParams()
    dispatch(searchByParams(params))
  }

  const handleCLickCleanFilter = () => {
    resetSearchParams()
    dispatch(searchByParams({}))
  }
  const handleOnChangeCampana = e => {
    setSelectedCampana(e.target.value)
  }

  const handleOnChangeStatus = e => {
    setSelectedStatus(e.target.value)
  }

  const getSearchParams = () => {
    return {
      campana: selectedCampana,
      status: selectedStatus,
      fecha_inicio: calendarValueStart,
      fecha_fin: calendarValueEnd,
    }
  }

  return (
    <JobListTemplate
      jobs={jobs}
      isLoading={isLoading}
      onInputSearchChange={handleInputSearchChange}
      onSearch={handleSearch}
      rutValue={searchParams.rut_cliente}
      handleOnClickViewJob={handleOnClickViewJob}
      handleOnClickDeleteJob={handleOnClickDeleteJob}
      handleUpdateJobList={handleUpdateJobList}
      totalPages={totalPages}
      currentPage={page}
      onPageChange={handlePageChange}
      campanas={campanas}
      status={status}
      handleOnChangeCampana={handleOnChangeCampana}
      handleOnChangeStatus={handleOnChangeStatus}
      handleClickFilter={handleClickFilter}
      handleCLickCleanFilter={handleCLickCleanFilter}
      selectedCampana={selectedCampana}
      selectedStatus={selectedStatus}
      setCalendarValueStart={setCalendarValueStart}
      calendarValueStart={calendarValueStart}
      setCalendarValueEnd={setCalendarValueEnd}
      calendarValueEnd={calendarValueEnd}
    />
  )
}

export default JobListContainer
