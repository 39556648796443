import { error } from "toastr"
import {
  LOCK_JOB_REQUEST,
  LOCK_JOB_SUCCESS,
  LOCK_JOB_ERROR,
  UNLOCK_JOB_REQUEST,
  UNLOCK_JOB_SUCCESS,
  UNLOCK_JOB_ERROR,
  CHECK_AVAILABLE_JOB,
  CHECK_AVAILABLE_JOB_SUCCESS,
  CHECK_AVAILABLE_JOB_ERROR,
} from "./actionTypes"

const initialState = {
  lockJob: {},
  unlockJob: {},
  loading: false,
  error: null,
  canAccess: false,
  lockMesagge: "",
}

const leaseJobsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCK_JOB_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case LOCK_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        lockJob: action.payload,
      }
    case LOCK_JOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload?.message || null,
      }
    case UNLOCK_JOB_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case UNLOCK_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        unlockJob: action.payload,
      }
    case UNLOCK_JOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload?.message || null,
      }

    case CHECK_AVAILABLE_JOB:
      return {
        ...state,
        loading: true,
      }
    case CHECK_AVAILABLE_JOB_SUCCESS:
      return {
        ...state,
        loading: false,
        canAccess: action.payload?.canAccess,
        lockMesagge: action.payload?.message,
      }
    case CHECK_AVAILABLE_JOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        canAccess: action.payload?.canAccess,
        message: action.payload?.message || null,
      }

    default:
      return state
  }
}

export default leaseJobsReducer
