import {
  IS_LOADING_EVALUATION_START,
  IS_LOADING_EVALUATION_END,
  IS_LOADING_SUMMARY_START,
  IS_LOADING_SUMMARY_END,
} from "./actionTypes"

export const isLoadingEvaluationStart = () => {
  return {
    type: IS_LOADING_EVALUATION_START,
  }
}

export const isLoadingEvaluationEnd = () => {
  return {
    type: IS_LOADING_EVALUATION_END,
  }
}

export const isLoadingSummaryStart = () => {
  return {
    type: IS_LOADING_SUMMARY_START,
  }
}

export const isLoadingSummaryEnd = () => {
  return {
    type: IS_LOADING_SUMMARY_END,
  }
}
