export const CREATE_OBSERVATION = "CREATE_OBSERVATION"
export const CREATE_OBSERVATION_SUCCESS = "CREATE_OBSERVATION_SUCCESS"
export const CREATE_OBSERVATION_ERROR = "CREATE_OBSERVATION_ERROR"

export const GET_OBSERVATIONS = "GET_OBSERVATIONS"
export const GET_OBSERVATIONS_SUCCESS = "GET_OBSERVATIONS_SUCCESS"
export const GET_OBSERVATIONS_ERROR = "GET_OBSERVATIONS_ERROR"

export const GET_OBSERVATION_BY_ID = "GET_OBSERVATION_BY_ID"
export const GET_OBSERVATION_BY_ID_SUCCESS = "GET_OBSERVATION_BY_ID_SUCCESS"
export const GET_OBSERVATION_BY_ID_ERROR = "GET_OBSERVATION_BY_ID_ERROR"

export const DELETE_OBSERVATION = "DELETE_OBSERVATION"
export const DELETE_OBSERVATION_SUCCESS = "DELETE_OBSERVATION_SUCCESS"
export const DELETE_OBSERVATION_ERROR = "DELETE_OBSERVATION_ERROR"

export const UPDATE_OBSERVATION = "UPDATE_OBSERVATION"
export const UPDATE_OBSERVATION_SUCCESS = "UPDATE_OBSERVATION_SUCCESS"
export const UPDATE_OBSERVATION_ERROR = "UPDATE_OBSERVATION_ERROR"
